export const regular = [
    [
        'Изменение суммы и скидки по штрафам с 1 января 2025г',
        60
    ],
    [
        'Подробности по нарушению',
        [
            [
                'Почему льготный период не 30 дней?',
                61
            ],
            [
                'Почему сумма больше, чем обычно?',
                62
            ],
            [
                'Почему скидка не 50%?',
                63
            ],
            [
                'Неверная статья нарушения или ее нет',
                8
            ],
            [
                'Неверная дата нарушения или ее нет',
                6
            ],
            [
                'Проблемы с адресом нарушения или картой',
                [
                    [
                        'Не указан адрес нарушения',
                        5
                    ],
                    [
                        'Неверное место на карте',
                        1
                    ]
                ]
            ],
            [
                'Нет фотографий нарушения',
                [
                    [
                        'ГИБДД (УИН начинается на 188)',
                        7
                    ],
                    [
                        'Комитет по транспорту  г. Санкт-Петербург',
                        9
                    ],
                    [
                        'ГКУ АМПП (УИН начинается на 0355)',
                        10
                    ],
                    [
                        'МАДИ (УИН начинается на 0356)',
                        11
                    ],
                    [
                        'Ространснадзор (УИН начинается на 106)',
                        [
                            [
                                'Легковой автомобиль',
                                12
                            ],
                            [
                                'Крупно-тоннажный транспорт',
                                13
                            ]
                        ]
                    ],
                    [
                        'Другое',
                        'ToFaqByUin'
                    ]
                ]
            ]
        ]
    ],
    [
        'Вопросы по погашению',
        [
            [
                'Штраф не погашен',
                2
            ],
            [
                'Штраф оплачен, но передан приставам',
                [
                    [
                        'Штраф оплачен в нашем сервисе',
                        14
                    ],
                    [
                        'Штраф оплачен в другом сервисе',
                        15
                    ]
                ]
            ],
            [
                'Открыто исполнительное производство (ИП ФССП)',
                [
                    [
                        'Узнать подробности по задолженности',
                        16
                    ],
                    [
                        'Погасить долг',
                        [
                            [
                                'Долг уже оплачен, но не гаснет',
                                [
                                    [
                                        'Прошло 2 недели с момента оплаты',
                                        [
                                            [
                                                'Долг не погас только в нашем сервисе',
                                                17
                                            ],
                                            [
                                                'Долг не погас везде',
                                                16
                                            ]
                                        ]
                                    ],
                                    [
                                        'Не прошло 2 недели с момента оплаты',
                                        18
                                    ]
                                ]
                            ],
                            [
                                'Долг не оплачен',
                                19
                            ]
                        ]
                    ],
                    [
                        'Появилось доначисление',
                        20
                    ],
                ]
            ]
        ]

    ],
    [
        'Штраф пришел без скидки',
        21
    ],
    [
        'Нет штрафа в приложении',
        [
            [
                'Проверить документы',
                22
            ],
            [
                'Документы указаны верно',
                [
                    [
                        'Есть номер постановления',
                        'ToUinPicker'
                    ],
                    [
                        'Нет номера постановления',
                        'FineTroubleReport'
                    ]
                ]
            ]
        ]
    ],
    [
        'Не согласен со штрафом',
        [

            [
                'Это не мой штраф',
                [
                    [
                        'Проверить документы',
                        22
                    ],
                    [
                        'Документы указаны верно',
                        [
                            [
                                'Фотографий нет',
                                'FineTroubleReport'
                            ],
                            [
                                'На фотографии чужой автомобиль',
                                23
                            ]
                        ]
                    ]
                ]
            ],
            [
                'Не согласен с ИП ФССП',
                3
            ],
            [
                'Штраф мой, хочу обжаловать',
                [
                    [
                        'ГИБДД (УИН начинается с 188)',
                        24.1
                    ],
                    [
                        'Комитет по транспорту  г. Санкт-Петербург',
                        27.1
                    ],
                    [
                        'ГКУ АМПП (УИН начинается с 0355)',
                        25.1
                    ],
                    [
                        'МАДИ (УИН начинается с 0356)',
                        26.1
                    ],
                    [
                        'Ространснадзор (УИН начинается с 106)',
                        [
                            [
                                'Легковой автомобиль',
                                28.1
                            ],
                            [
                                'Крупно-тоннажный транспорт',
                                29.1
                            ]
                        ]
                    ],
                    [
                        'Другое',
                        'ToFaqByUin'
                    ]
                ]
            ]
        ]
    ],
    [
        'Проверить штрафы, автомобиль, водительское удостоверение',
        [
            [
                'Проверить штрафы на автомобиль',
                [
                    [
                        'Есть номер постановления',
                        'ToUinPicker'
                    ],
                    [
                        'Нет номера постановления',
                        [
                            [
                                'Автомобиль зарегистрирован в РФ',
                                22
                            ],
                            [
                                'Автомобиль зарегистрирован в другой стране',
                                30
                            ]
                        ]
                    ]
                ]
            ],
            [
                'Проверить штрафы по водительскому удостоверению',
                [
                    [
                        'Есть номер постановления',
                        'ToUinPicker'
                    ],
                    [
                        'Нет номера постановления',
                        [
                            [
                                'Водительское удостоверение российского образца',
                                22
                            ],
                            [
                                'Водительское удостоверение зарубежного образца',
                                31
                            ]
                        ]
                    ]
                ]
            ],
            [
                'Проверить ограничения на автомобиль',
                32
            ],
            [
                'Проверить водительское удостоверение',
                33
            ],
            [
                'Проверить штраф по номеру постановления (УИН)',
                'ToUinPicker'
            ]
        ]
    ],
    [
        'Общие вопросы',
        [
            [
                'Автомобиль продан, но приходят штрафы',
                34
            ],
            [
                'Как удалить штрафы из истории?',
                35
            ],
            [
                'Как удалить автомобиль?',
                36
            ],
            [
                'Перенос данных',
                [
                    [
                        'Перенос данных между устройствами Android',
                        37
                    ],
                    [
                        'Перенос данных между другими устройствами',
                        38
                    ]
                ]
            ]
        ]
    ],
    [
        'Информация о штрафе за парковку',
        [
            [
                'Москва',
                [
                    [
                        'ГКУ АМПП (УИН начинается с 0355)',
                        25.2
                    ],
                    [
                        'МАДИ (УИН начинается с 0356)',
                        26.2
                    ]
                ]
            ],
            [
                'Санкт Петербург',
                [
                    [
                        'Нарушение стоянки в зоне действия дорожных знаков 3.27-3.30',
                        41
                    ],
                    [
                        'Нарушение правил парковки в платной зоне',
                        41
                    ],
                    [
                        'Штраф за парковку во дворе',
                        42
                    ]
                ]
            ],
            [
                'Казань',
                43
            ],
            [
                'Белгород',
                44
            ],
            [
                'Тверь',
                45
            ],
            [
                'Калуга',
                46
            ],
            [
                'Тула',
                47
            ],
            [
                'Ижевск(Удмуртия)',
                48
            ],
            [
                'Пермь',
                49
            ],
            [
                'Воронеж',
                50
            ],
            [
                'Тюмень',
                51
            ],
            [
                'Красноярск',
                52
            ],
            [
                'Ростов-На-Дону',
                53
            ],
            [
                'Екатеринбург',
                54
            ],
            [
                'Курск',
                55
            ],
            [
                'Другое',
                'ToFaqByUin'
            ]
        ]
    ],
    [
        'Мало данных по нарушению',
        'FineTroubleReport'
    ],
    [
        'Другой вопрос',
        59
    ]
];
export const vip = [
    [
        'Изменение суммы и скидки по штрафам с 1 января 2025г',
        60
    ],
    [
        'Подробности по нарушению',
        [
            [
                'Почему льготный период не 30 дней?',
                61
            ],
            [
                'Почему сумма больше, чем обычно?',
                62
            ],
            [
                'Почему скидка не 50%?',
                63
            ],
            [
                'Неверная статья нарушения или ее нет',
                8
            ],
            [
                'Неверная дата нарушения или ее нет',
                6
            ],
            [
                'Проблемы с адресом нарушения или картой',
                [
                    [
                        'Не указан адрес нарушения',
                        5
                    ],
                    [
                        'Неверное место на карте',
                        1
                    ]
                ]
            ],
            [
                'Нет фото нарушения',
                [
                    [
                        'ГИБДД (УИН начинается с 188)',
                        7
                    ],
                    [
                        'Комитет по транспорту  г. Санкт-Петербург',
                        9
                    ],
                    [
                        'ГКУ АМПП (УИН начинается с 0355)',
                        10
                    ],
                    [
                        'МАДИ (УИН начинается с 0356)',
                        11
                    ],
                    [
                        'Ространснадзор (УИН начинается с 106)',
                        [
                            [
                                'Легковой автомобиль',
                                12
                            ],
                            [
                                'Крупно-тоннажный транспорт',
                                13
                            ]
                        ]
                    ],
                    [
                        'Другое',
                        'ToFaqByUin'
                    ]
                ]
            ]
        ]
    ],
    [
        'Вопросы по погашению',
        [
            [
                'Штраф оплачен в нашем сервисе и не погашен',
                [
                    [
                        'По штрафу открыто ИП ФССП',
                        14
                    ],
                    [
                        'По штрафу НЕ открыто ИП ФССП',
                        56
                    ]
                ]
            ],
            [
                'Штраф оплачен в другом сервисе и не погашен',
                2
            ],
            [
                'Штраф оплачен, но передан приставам',
                [
                    [
                        'Штраф оплачен в нашем сервисе',
                        14
                    ],
                    [
                        'Штраф оплачен в другом сервисе',
                        15
                    ]
                ]
            ],
            [
                'Открыто исполнительное производство (ИП ФССП)',
                [
                    [
                        'Узнать подробности по задолженности',
                        16
                    ],
                    [
                        'Погасить долг',
                        [
                            [
                                'Долг уже оплачен, но не гаснет',
                                [
                                    [
                                        'Оплата была в нашем сервисе',
                                        [
                                            [
                                                'Прошло 30 дней с момента оплаты',
                                                '30DaysHasPassed'
                                            ],
                                            [
                                                'Не прошло 30 дней с момента оплаты',
                                                57
                                            ]
                                        ]
                                    ],
                                    [
                                        'Оплата была в другом сервисе',
                                        [
                                            [
                                                'Прошло 2 недели с момента оплаты',
                                                [
                                                    [
                                                        'Долг не погас только в нашем сервисе',
                                                        17
                                                    ],
                                                    [
                                                        'Долг не погас везде',
                                                        16
                                                    ]
                                                ]
                                            ],
                                            [
                                                'Не прошло 2 недели с момента оплаты',
                                                18
                                            ]
                                        ]
                                    ]
                                ]
                            ],
                            [
                                'Долг не оплачен',
                                19
                            ]
                        ]
                    ],
                    [
                        'Появилось доначисление',
                        20,
                    ]
                ]
            ]
        ]

    ],
    [
        'Штраф пришел без скидки',
        21
    ],
    [
        'Нет штрафа в приложении',
        [
            [
                'Проверить документы',
                22
            ],
            [
                'Документы указаны верно',
                [
                    [
                        'Есть номер постановления',
                        'ToUinPicker'
                    ],
                    [
                        'Нет номера постановления',
                        'HasNoUIN'
                    ]
                ]
            ]
        ]
    ],
    [
        'Не согласен со штрафом',
        [
            [
                'Это не мой штраф',
                [
                    [
                        'Проверить документы',
                        22
                    ],
                    [
                        'Документы указаны верно',
                        [
                            [
                                'Фотографий нет',
                                'HasNoPhotos'
                            ],
                            [
                                'На фотографии чужой автомобиль',
                                23
                            ]
                        ]
                    ]
                ]
            ],
            [
                'Не согласен с ИП ФССП',
                'NotAgreeWithFineOrder'
            ],
            [
                'Штраф мой, хочу обжаловать',
                [
                    [
                        'ГИБДД (УИН начинается с 188)',
                        24.2
                    ],
                    [
                        'Комитет по транспорту  г. Санкт-Петербург',
                        27.2
                    ],
                    [
                        'ГКУ АМПП (УИН начинается с 0355)',
                        25.2
                    ],
                    [
                        'МАДИ (УИН начинается с 0356)',
                        26.2
                    ],
                    [
                        'Ространснадзор (УИН начинается с 106)',
                        [
                            [
                                'Легковой автомобиль',
                                28.2
                            ],
                            [
                                'Крупно-тоннажный транспорт',
                                29.2
                            ]
                        ]
                    ],
                    [
                        'Другое',
                        'ToFaqByUin'
                    ]
                ]
            ]
        ]
    ],
    [
        'Проверить штрафы, автомобиль, водительское удостоверение',
        [
            [
                'Проверить штрафы на автомобиль',
                [
                    [
                        'Нет номера постановления',
                        [
                            [
                                'Автомобиль зарегистрирован в РФ',
                                22
                            ],
                            [
                                'Автомобиль зарегистрирован в другой стране',
                                30
                            ]
                        ]
                    ],
                    [
                        'Есть номер постановления',
                        'ToUinPicker'
                    ],

                ]
            ],
            [
                'Проверить штрафы по водительскому удостоверению',
                [
                    [
                        'Нет номера постановления',
                        [
                            [
                                'Водительское удостоверение российского образца',
                                22
                            ],
                            [
                                'Водительское удостоверение зарубежного образца',
                                31
                            ]
                        ]
                    ],
                    [
                        'Есть номер постановления',
                        'ToUinPicker'
                    ],

                ]
            ],
            [
                'Проверить ограничения на автомобиль',
                32
            ],
            [
                'Проверить водительское удостоверение',
                33
            ],
            [
                'По номеру постановления (УИН)',
                'ToUinPicker'
            ]
        ]
    ],
    [
        'Общие вопросы',
        [
            [
                'Автомобиль продан, но приходят штрафы',
                34
            ],
            [
                'Как удалить штрафы из истории?',
                35
            ],
            [
                'Как удалить автомобиль?',
                36
            ],
            [
                'Перенос данных',
                [
                    [
                        'Перенос данных между устройствами Android',
                        37
                    ],
                    [
                        'Перенос данных между другими устройствами',
                        38
                    ]
                ]
            ],
            [
                'Нет моего вопроса',
                'HasNoMyQuestion'
            ]
        ]
    ],
    [
        'Информация о штрафе за парковку',
        [
            [
                'Москва',
                [
                    [
                        'ГКУ АМПП (УИН начинается с 0355)',
                        25.2
                    ],
                    [
                        'МАДИ (УИН начинается с 0356)',
                        26.2
                    ]
                ]
            ],
            [
                'Санкт Петербург',
                [
                    [
                        'Нарушение стоянки в зоне действия дорожных знаков 3.27-3.30',
                        41
                    ],
                    [
                        'Нарушение правил парковки в платной зоне',
                        41
                    ],
                    [
                        'Штраф за парковку во дворе',
                        42
                    ]
                ]
            ],
            [
                'Казань',
                43
            ],
            [
                'Белгород',
                44
            ],
            [
                'Тверь',
                45
            ],
            [
                'Калуга',
                46
            ],
            [
                'Тула',
                47
            ],
            [
                'Ижевск(Удмуртия)',
                48
            ],
            [
                'Пермь',
                49
            ],
            [
                'Воронеж',
                50
            ],
            [
                'Тюмень',
                51
            ],
            [
                'Красноярск',
                52
            ],
            [
                'Ростов-На-Дону',
                53
            ],
            [
                'Екатеринбург',
                54
            ],
            [
                'Курск',
                55
            ],
            [
                'Другое',
                'ToFaqByUin'
            ]
        ]
    ],
    [
        'Мало данных по нарушению',
        'FineTroubleReport'
    ],
    [
        'Другой вопрос',
        58
    ]
];
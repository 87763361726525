export const regular = [
    [
        'Акции',
        [
            [
                'Мандариновый патруль',
                'mandarin'
            ],
            [
                'Ноябрьская акция Росштрафы',
                'fueldraw2'
            ],
            [
                'ОСАГО Кэшбек № 4',
                'promo_osago25'
            ],
        ]
    ],
    [
        'Предложения',
        [
            [
                'Обжалование штрафов',
                1
            ]
        ]
    ],
    [
        'Жалобы и обратная связь по акциям, подпискам и доп. услугам',
        [
            [
                'Жалоба на рассылку',
                [
                    [
                        'Рассылка по почте',
                        2
                    ],
                    [
                        'Push-уведомления',
                        3
                    ]
                ]
            ],
            [
                'Другая проблема по подписке, услуге, акции',
                'OtherSubscriptionProblem',
            ]
        ]
    ]
];